import { AspectRatio, Responsive } from './VideoEmbed.style'

import { EmbeddedVideo } from '~/types'
import React from 'react'
import ReactPlayer from 'react-player'

type ViewProps = {
    embeddedVideo: EmbeddedVideo
}

export default ({ embeddedVideo }: ViewProps) => (
    <Responsive>
        <AspectRatio>
            <ReactPlayer url={embeddedVideo.url} controls height="auto" playsinline={false} />
        </AspectRatio>
    </Responsive>
)
