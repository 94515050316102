import { Box, Grid, Hidden } from '@material-ui/core'

import ColorTypography from '~/components/ColorTypography'
import { Container } from './ProjectInfo.style'
import Credit from './Credit'
import Desktop from '~/components/Desktop'
import ExternalLink from '~/components/ExternalLink'
import LineBreak from '~/components/LineBreak'
import { Project } from '~/types'
import ProjectTitle from '~/components/ProjectTitle'
import React from 'react'
import RichText from '~/components/RichText'
import Scrollable from '~/components/Scrollable'
import TypographyButton from '~/components/TypographyButton'
import { Unicode } from '~/constants/unicode'
import { contentMargin } from '~/styles/sizes'
import moment from 'moment'

type ViewProps = {
    project: Project
    onClose(): void
}

export default ({ project, onClose }: ViewProps) => {
    const { title, description, location, date, credits, exhibitions, _rawText } = project

    return (
        <Container>
            <ProjectTitle title={title} />
            <Box
                position="absolute"
                top={0}
                left={0}
                width="100%"
                zIndex={5000}
                padding={contentMargin}
            >
                <TypographyButton title={Unicode.CLOSE} onClick={onClose} />
            </Box>
            <Grid container>
                <Grid item xs={12} md={6}>
                    <Scrollable>
                        <Box paddingLeft={contentMargin} paddingRight={contentMargin}>
                            <ColorTypography variant="h1">
                                {description}
                                <br />
                                {location}, {moment(date).format(`DD MMM YYYY`)}
                                <br />
                                <Desktop>
                                    <br />
                                </Desktop>
                            </ColorTypography>
                            <Grid container>
                                {credits.map((credit, index) => (
                                    <Credit
                                        credit={credit}
                                        hideLabel={credits[index - 1]?.label === credit.label}
                                        key={index}
                                    />
                                ))}
                            </Grid>
                            {exhibitions.length && (
                                <>
                                    <Box display="block">
                                        <ColorTypography variant="h1">
                                            <br />
                                            EXHIBITIONS
                                        </ColorTypography>
                                    </Box>

                                    {exhibitions.map((exhibition) => (
                                        <>
                                            <ExternalLink
                                                link={{
                                                    title: `${exhibition.name}, ${
                                                        exhibition.location
                                                    }, ${moment(exhibition.date).format(
                                                        `DD MMM YYYY`
                                                    )}`,
                                                    url: exhibition.url
                                                }}
                                            />
                                            <br />
                                        </>
                                    ))}
                                </>
                            )}
                            <Hidden mdUp>
                                <LineBreak variant="h1" lines={2} />
                                <RichText body={_rawText} />
                            </Hidden>
                        </Box>
                    </Scrollable>
                </Grid>
                <Hidden smDown>
                    <Grid item xs={12} md={6}>
                        <Scrollable>
                            <Box paddingLeft={contentMargin} paddingRight={contentMargin}>
                                <RichText body={_rawText} />
                            </Box>
                        </Scrollable>
                    </Grid>
                </Hidden>
            </Grid>
        </Container>
    )
}
