import { contentMargin } from '~/styles/sizes'
import styled from 'styled-components'

export const Container = styled.div`
    position: absolute;
    z-index: 5000;
    pointer-events: all;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: black;
`
