import { Box } from '@material-ui/core'
import ColorTypography from '~/components/ColorTypography'
import { Credit } from '~/types'
import Desktop from '~/components/Desktop'
import ExternalLink from '~/components/ExternalLink'
import Mobile from '~/components/Mobile'
import React from 'react'

type ViewProps = {
    credit: Credit
    hideLabel: boolean
}

export default ({ credit, hideLabel }: ViewProps) => {
    const { label, link } = credit

    return (
        <>
            <Mobile>
                {!hideLabel && (
                    <Box width="100%">
                        <ColorTypography variant="h1">
                            <br />
                            {label}
                        </ColorTypography>
                    </Box>
                )}

                <Box width="100%">
                    <ExternalLink link={link} />
                </Box>
            </Mobile>
            <Desktop>
                <Box display="flex" width="100%">
                    <Box flexGrow={1}>
                        <ColorTypography variant="h1">{hideLabel ? '' : label}</ColorTypography>
                    </Box>
                    <ExternalLink link={link} />
                </Box>
            </Desktop>
        </>
    )
}
