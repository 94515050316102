import { contentMargin, contentSpacing } from '~/styles/sizes'

import styled from 'styled-components'

export const Responsive = styled.div`
    width: 100%;
    max-height: 80vh;
    overflow: hidden;
    position: relative;
    ${({ theme }) => `            
        ${theme.breakpoints.up('md')} {        
            width: 60%;
        }
    `}

    * {
        height: 100%;
    }
`

export const ImageIndex = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    padding: ${({ theme }) => theme.spacing(contentMargin)};
`
