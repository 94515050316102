import styled from 'styled-components'

export const Container = styled.div<{ side: 'left' | 'right'; isHover: boolean }>`
    position: fixed;
    top: 25vh;
    width: 100%;
    height: 100%;
    transform: translate(${({ side }) => (side === 'left' ? -55 : 55)}%);
    pointer-events: none;
    opacity: ${({ isHover }) => (isHover ? 1 : 0)};
    transition: opacity 0.2s linear;

    video {
        height: 50vh;
        margin-left: 50%;
        transform: translateX(-50%);
        mask-image: linear-gradient(
            to ${({ side }) => side},
            transparent 0%,
            transparent 10%,
            rgba(0, 0, 0, 1) 50%
        );
        pointer-events: all;
        cursor: pointer;
    }
`

export const Label = styled.div<{ side: 'left' | 'right'; isHover: boolean }>`
    position: fixed;
    top: 0;
    pointer-events: none;
    height: 100vh;
    display: flex;
    justify-content: center;
    flex-direction: column;

    ${({ side }) =>
        side === 'left'
            ? `
        left: 0;
    `
            : `
        right: 0;
    `};
`
