import { Box, withWidth } from '@material-ui/core'
import { CloseButton, HorizontalSlider, SelectedContainer } from './ImageCarousel.style'
import React, { useCallback, useRef, useState } from 'react'

import { Breakpoint } from '@material-ui/core/styles/createBreakpoints'
import ColorTypography from '~/components/ColorTypography'
import { Dialog } from '@material-ui/core'
import { ImageGallery } from '~/types'
import ImageStack from '~/components/ImageStack'
import Thumbnail from './Thumbnail'
import { Unicode } from '~/constants/unicode'
import useAnimationFrame from '~/hooks/useAnimationFrame'

type ViewProps = {
    gallery: ImageGallery
    width: Breakpoint
}

export default withWidth()(({ gallery, width }: ViewProps) => {
    const isMobile = ['xs', 'sm'].includes(width)
    const thumbnailsPerWidth = isMobile ? 3 : 5
    const inertiaInitial = 75
    const friction = 5
    const [isHoverInit, setIsHoverInit] = useState(isMobile)
    const inertia = useRef(inertiaInitial)
    const isHover = useRef(false)
    const hoverPosition = useRef<number>(0)
    const sliderRef = useRef<HTMLDivElement>(null)
    const isAnimated = gallery.images.length > thumbnailsPerWidth
    const [selectedIndex, setSelectedIndex] = useState<number>()
    const carouselImages = isAnimated
        ? gallery.images.concat(gallery.images.slice(0, thumbnailsPerWidth))
        : gallery.images

    useAnimationFrame(() => {
        if (!sliderRef.current) {
            return
        }

        inertia.current =
            isHover.current && !selectedIndex ? inertiaInitial : inertia.current + friction

        const centerPoint = window.innerWidth / 2
        const momentum = isMobile
            ? 0
            : Math.floor((hoverPosition.current - centerPoint) / inertia.current)
        const isOutOfBoundsLeft = sliderRef.current.scrollLeft + momentum <= 0
        const isOutOfBoundsRight =
            sliderRef.current.scrollLeft + momentum >=
            sliderRef.current.scrollWidth - window.innerWidth

        if (isOutOfBoundsLeft) {
            sliderRef.current.scrollLeft =
                sliderRef.current.scrollWidth - sliderRef.current.clientWidth - 1
        } else if (isOutOfBoundsRight) {
            sliderRef.current.scrollLeft = 1
        }

        if (!isMobile && inertia.current < 300) {
            sliderRef.current.scrollBy({ left: momentum })
        }
    }, isHoverInit && isAnimated)

    const onSelect = useCallback((index: number) => {
        setSelectedIndex(index)
    }, [])

    const onCloseSelected = () => {
        setSelectedIndex(undefined)
    }

    return (
        <>
            <HorizontalSlider
                id="slider"
                onMouseMove={(event) => (hoverPosition.current = event.clientX)}
                onMouseEnter={() => {
                    isHover.current = true
                    setIsHoverInit(true)
                }}
                onMouseLeave={() => {
                    isHover.current = false
                }}
                isAnimated={isAnimated}
                ref={sliderRef}
            >
                {carouselImages.map((image, index) => (
                    <Thumbnail
                        key={index}
                        fluid={image.asset.fluid}
                        thumbnailsPerWidth={thumbnailsPerWidth}
                        onClick={() => onSelect(index)}
                    />
                ))}
            </HorizontalSlider>
            <Dialog
                open={selectedIndex !== undefined}
                onClose={onCloseSelected}
                maxWidth="xl"
                style={{ zIndex: 5000 }}
            >
                <SelectedContainer>
                    <ImageStack
                        gallery={gallery}
                        startIndex={selectedIndex! % gallery.images.length}
                    />
                </SelectedContainer>
                <CloseButton onClick={onCloseSelected}>
                    <ColorTypography variant="h1">{Unicode.CLOSE}</ColorTypography>
                </CloseButton>
            </Dialog>
        </>
    )
})
