import { Box, Slide } from '@material-ui/core'
import { Container, Label } from './ProjectSideLink.style'
import React, { useState } from 'react'

import ColorTypography from '~/components/ColorTypography'
import { Link } from 'gatsby'
import { Project } from '~/types'

type ViewProps = {
    project: Project
    side: 'left' | 'right'
}

export default ({ project, side }: ViewProps) => {
    const [isHover, setIsHover] = useState(false)
    const { url, teaserVideoFile } = project

    return (
        <>
            <Container side={side} isHover={isHover}>
                <Link to={`/projects/${url.current}`}>
                    <video
                        muted
                        autoPlay
                        loop
                        playsInline
                        onMouseEnter={() => setIsHover(true)}
                        onMouseLeave={() => setIsHover(false)}
                    >
                        <source src={teaserVideoFile.asset.url} type="video/mp4" />
                    </video>
                </Link>
            </Container>
            <Slide in={isHover} direction={side === 'left' ? 'right' : 'left'}>
                <Label side={side} isHover={isHover}>
                    <Box padding="4vw">
                        <ColorTypography variant="h1">
                            {side === 'left' ? 'PREVIOUS' : 'NEXT'}
                        </ColorTypography>
                    </Box>
                </Label>
            </Slide>
        </>
    )
}
