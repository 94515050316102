import { EmbeddedVideo, ImageGallery } from '~/types'

import { ContentType } from '~/constants/contentType'
import ImageCarousel from '~/components/ImageCarousel'
import ImageStack from '~/components/ImageStack'
import React from 'react'
import VideoEmbed from '~/components/VideoEmbed'

type ViewProps = {
    content: EmbeddedVideo | ImageGallery
}

export default ({ content }: ViewProps) => {
    const components: Record<string, JSX.Element> = {
        [ContentType.EmbeddedVideo]: <VideoEmbed embeddedVideo={content as EmbeddedVideo} />,
        [ContentType.ImageCarousel]: <ImageCarousel gallery={content as ImageGallery} />,
        [ContentType.ImageStack]: <ImageStack gallery={content as ImageGallery} />
    }

    return components[content.__typename]
}
