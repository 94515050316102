import Img, { FluidObject } from 'gatsby-image'

import React from 'react'
import { ThumbnailContainer } from './Thumbnail.style'

type ViewProps = {
    fluid: FluidObject
    thumbnailsPerWidth: number
    onClick(): void
}

export default ({ fluid, thumbnailsPerWidth, onClick }: ViewProps) => (
    <ThumbnailContainer onClick={onClick} thumbnailsPerWidth={thumbnailsPerWidth}>
        <Img fluid={fluid} />
    </ThumbnailContainer>
)
