import ColorTypography from '~/components/ColorTypography'
import React from 'react'
import { TitleContainer } from './ProjectTitle.style'

type ViewProps = {
    title: string
}
export default ({ title }: ViewProps) => (
    <TitleContainer>
        <ColorTypography variant="h1" align="center">
            {title.toUpperCase()}
        </ColorTypography>
    </TitleContainer>
)
