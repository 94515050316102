import { Box, Grid } from '@material-ui/core'
import React, { useState } from 'react'
import { contentMargin, contentSpacing } from '~/styles/sizes'

import Desktop from '~/components/Desktop'
import Head from '~/components/Head'
import LineBreak from '~/components/LineBreak'
import { Project } from '~/types'
import ProjectContent from '~/components/ProjectContent'
import ProjectInfo from '~/components/ProjectInfo'
import ProjectSideLink from '~/components/ProjectSideLink'
import ProjectTitle from '~/components/ProjectTitle'
import Scrollable from '~/components/Scrollable'
import TypographyButton from '~/components/TypographyButton'
import { Unicode } from '~/constants/unicode'
import { graphql } from 'gatsby'

export const query = graphql`
    query($id: String!, $prevId: String, $nextId: String) {
        sanityProject(id: { eq: $id }) {
            title
            description
            seoImage {
                asset {
                    url
                }
            }
            location
            date
            credits {
                label
                link {
                    url
                    title
                }
            }
            exhibitions {
                name
                location
                date
                url
            }
            content {
                ... on SanityEmbeddedVideo {
                    url
                }
                ... on SanityImageCarousel {
                    images {
                        caption
                        asset {
                            fluid(maxWidth: 2400) {
                                ...GatsbySanityImageFluid
                            }
                        }
                    }
                }
                ... on SanityImageStack {
                    images {
                        caption
                        asset {
                            fluid(maxWidth: 2400) {
                                ...GatsbySanityImageFluid
                            }
                        }
                    }
                }
            }
            _rawText(resolveReferences: { maxDepth: 5 })
        }
        prevProject: sanityProject(id: { eq: $prevId }) {
            title
            url {
                current
            }
            teaserVideoFile {
                asset {
                    url
                }
            }
        }
        nextProject: sanityProject(id: { eq: $nextId }) {
            title
            url {
                current
            }
            teaserVideoFile {
                asset {
                    url
                }
            }
        }
    }
`

type ViewProps = {
    data: {
        sanityProject: Project
        prevProject?: Project
        nextProject?: Project
    }
}

export default ({ data }: ViewProps) => {
    const [isInfoActive, setIsInfoActive] = useState(false)
    const { sanityProject, prevProject, nextProject } = data
    // todo: add seo image to project
    const { title, description, seoImage, content } = sanityProject

    return (
        <>
            <Head title={title} description={description} image={seoImage?.asset.url} />
            <ProjectTitle title={title} />

            <Scrollable>
                <Desktop>
                    {prevProject && <ProjectSideLink project={prevProject} side="left" />}
                    {nextProject && <ProjectSideLink project={nextProject} side="right" />}
                </Desktop>

                <Grid container>
                    {content.map((contentItem, index) => (
                        <Grid item xs={12} key={index}>
                            <Box
                                display="flex"
                                width="100%"
                                justifyContent="center"
                                paddingTop={contentSpacing}
                            >
                                <ProjectContent content={contentItem} />
                            </Box>
                        </Grid>
                    ))}
                </Grid>
                <LineBreak variant="h1" lines={4} />
                <Box position="fixed" bottom={0} left={0} padding={contentMargin}>
                    <TypographyButton
                        title={`${Unicode.LINK} INFO`}
                        onClick={() => setIsInfoActive(true)}
                    />
                </Box>
            </Scrollable>
            {isInfoActive && (
                <ProjectInfo project={data.sanityProject} onClose={() => setIsInfoActive(false)} />
            )}
        </>
    )
}
