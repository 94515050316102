import { contentMargin } from '~/styles/sizes'
import styled from 'styled-components'

export const HorizontalSlider = styled.div<{ isAnimated: boolean }>`
    width: 100vw;
    overflow-x: scroll;
    white-space: nowrap;
    cursor: pointer;
    pointer-events: all;

    ${({ isAnimated }) =>
        !isAnimated &&
        `
        display: flex;
        justify-content: center;
    `}
`

export const SelectedContainer = styled.div`
    width: 100vw;
`

export const CloseButton = styled.div`
    position: fixed;
    cursor: pointer;
    top: ${({ theme }) => theme.spacing(contentMargin)};
    right: ${({ theme }) => theme.spacing(contentMargin)};
`
