import { Box, Grid } from '@material-ui/core'
import { ImageIndex, Responsive } from './ImageStack.style'
import React, { useState } from 'react'

import ColorTypography from '~/components/ColorTypography'
import Desktop from '~/components/Desktop'
import { ImageGallery } from '~/types'
import Img from 'gatsby-image'
import Mobile from '~/components/Mobile'
import MouseTooltip from 'react-sticky-mouse-tooltip'

type ViewProps = {
    gallery: ImageGallery
    startIndex?: number
}

export default ({ gallery, startIndex = 0 }: ViewProps) => {
    console.log('index', startIndex)
    const [ishover, setIsHover] = useState(false)
    const [activeIndex, setActiveIndex] = useState(startIndex)

    const onClick = () => {
        if (gallery.images.length <= 1) {
            return
        }
        const newIndex = activeIndex < gallery.images.length - 1 ? activeIndex + 1 : 0
        setActiveIndex(newIndex)
    }

    const onMouseEnter = () => {
        setIsHover(true)
    }

    const onMouseLeave = () => {
        setIsHover(false)
    }

    return (
        <Grid container>
            <Grid item xs={12}>
                <Box width="100%" display="flex" justifyContent="center">
                    <Responsive>
                        <Box
                            onClick={onClick}
                            onMouseEnter={onMouseEnter}
                            onMouseMove={onMouseEnter}
                            onMouseLeave={onMouseLeave}
                        >
                            <Img
                                fluid={gallery.images[activeIndex].asset.fluid}
                                imgStyle={{
                                    objectFit: 'contain'
                                }}
                            />
                        </Box>
                        {gallery.images.length > 1 && (
                            <>
                                <Mobile>
                                    <ImageIndex>
                                        <ColorTypography variant="h1">
                                            {activeIndex + 1}/{gallery.images.length}
                                        </ColorTypography>
                                    </ImageIndex>
                                </Mobile>
                                {ishover && (
                                    <Desktop>
                                        <MouseTooltip visible={true} offsetX={15} offsetY={10}>
                                            <ColorTypography variant="h1">
                                                {`${activeIndex + 1}/${gallery.images.length}`}
                                            </ColorTypography>
                                        </MouseTooltip>
                                    </Desktop>
                                )}
                            </>
                        )}
                    </Responsive>
                </Box>
            </Grid>
            <Grid item xs={12}>
                <Box paddingTop={3}>
                    <ColorTypography variant="h3" align="center">
                        {gallery.images[activeIndex].caption}
                    </ColorTypography>
                </Box>
            </Grid>
        </Grid>
    )
}
