import { contentSpacing } from '~/styles/sizes'
import styled from 'styled-components'

export const Responsive = styled.div`
    width: 100%;
    pointer-events: all;

    ${({ theme }) => `            
        ${theme.breakpoints.up('md')} {        
            width: 60%;
        }
    `}
`

export const AspectRatio = styled.div`
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: ${(100 * 9) / 16}%;
    background: black;

    iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
`
