import styled from 'styled-components'

export const ThumbnailContainer = styled.div<{ thumbnailsPerWidth: number }>`
    width: ${({ thumbnailsPerWidth }) => 100 / thumbnailsPerWidth}vw;
    max-height: ${({ thumbnailsPerWidth }) => ((9 / 16) * 100) / thumbnailsPerWidth}vw;
    img {
        max-height: ${({ thumbnailsPerWidth }) => ((9 / 16) * 100) / thumbnailsPerWidth}vw;
    }

    ${({ theme }) => `    
        ${theme.breakpoints.up('md')} {
            width: 20vw;
            max-height: ${(9 / 16) * 20}vw;
            img {
                max-height: ${(9 / 16) * 20}vw;
            }
        }
    `}

    display: inline-block;
    overflow: hidden;

    &:hover {
        filter: invert(0.1);
    }
`
